import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type {
  AarpMembershipSubParams,
  CouponParams,
  DefaultProductsParams,
  EnrollRsaParams,
  EnrollRsaResponse,
  ErrorResponse,
  IpAddressParams,
  MultipriceParams,
  MultipriceResponse,
  PasswordChangeResponse,
  PasswordResetParams,
  PurchaseAnonymousParams,
  PurchaseAnonymousResponse,
  PurchaseReceiptPriceParams,
  PurchaseReceiptStatusParams,
  PurchaseReceiptStatusResponse,
  SuccessResponse,
  VerifyEmailParams,
  VideoPurchaseAnonymousParams,
  XgritAarpResponse,
  XgritCouponResponse,
  XgritPricingResponse,
  XgritProduct
} from '@xcc-models';
import { Brand, XccApiEndpoints } from '@xcc-models';
import { catchError, of, retry, throwError, timeout, timer, type Observable } from 'rxjs';
import { IdGeneratorService } from './id-generator.service';
import { RsaClaimStatusService } from './rsa-claim-status.service';
import { XccEnvironment } from './xcc-environment';

import { BrandService } from '@xcc-client/services/lib/brand-service/brand.service';
import { PrepaidCodeService } from './prepaid-code.service';
import { TenantService } from './tenant.service';

const TIMEOUT_DURATION = 50000;
const TIMEOUT_RETRIES = 1;

/**
 * FRONT-END service for interacting with Xgrit API
 */

@Injectable({
  providedIn: 'root',
})
export class XgritApiService {
  private rsaClaimStatus = false;
  private rsaComponentLoaded = false;
  private isDiscountConditional = false;
  private brandId: string;
  private _couponCodeId: string; // ID of coupon code
  isPrepaid: boolean;

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly route: ActivatedRoute,
    private readonly http: HttpClient,
    private readonly idService: IdGeneratorService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
    private readonly prepaidCodeService: PrepaidCodeService,
    private readonly tenantService: TenantService,
    private readonly brandService: BrandService,
  ) {
    this.brandId = this.xccEnv.brand?.toUpperCase();

    this.prepaidCodeService.currentCodeStatus.subscribe((value: boolean) => {
      this.isPrepaid = value;
    });

    this.rsaClaimStatusService.rsaClaimStatus.subscribe((status) => (this.rsaClaimStatus = status));
    this.rsaClaimStatusService.rsaComponentLoaded.subscribe(
      (componentLoaded) => (this.rsaComponentLoaded = componentLoaded),
    );
    this.rsaClaimStatusService.isDiscountConditional.subscribe(
      (componentLoaded) => (this.isDiscountConditional = componentLoaded),
    );
  }

  // This could be the ID of either the coupon or bundleCoupon
  set couponCodeId(id: string) {
    this._couponCodeId = id;
  }

  private postXgritResource<T>(
    endpoint: string,
    params:
      | PurchaseReceiptPriceParams
      | PurchaseAnonymousParams
      | PasswordResetParams
      | EnrollRsaParams
      | PurchaseReceiptStatusParams
      | MultipriceParams
      | DefaultProductsParams
      | VerifyEmailParams
      | IpAddressParams
  ): Observable<T> {
    const body = {
      ...params,
      brandId: this.brandService.getBrandById(this.brandId),
      idempotencyKey: this.idService.uniqueIdentifier,
    };

    const resourceUrl = this.tenantService.getUrlForResource(endpoint);
    return this.http.post<T>(resourceUrl, body).pipe(
      // TODO: Testing this functionality for bad networks
      timeout(TIMEOUT_DURATION),
      retry({
        count: TIMEOUT_RETRIES,
        delay: (error: HttpErrorResponse, retryCount: number) => {
          if (retryCount > TIMEOUT_RETRIES || error?.statusText !== 'Timeout') {
            // If it's not a network error or retry count exceeded, throw error
            return throwError(() => error);
          }

          return timer(TIMEOUT_DURATION);
        },
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => this.errorHandling(error));
      }),
    );
  }

  get timeoutDuration() {
    return TIMEOUT_DURATION;
  }

  /**
   * Conditionally removes couponIdList/couponCodeList field from params.
   *
   * If the discount is conditional, RSA component is loaded and visible to the user, AND
   * the user didn't claim RSA, then don't apply the coupon. The user will be paying full
   * price for the course.
   */
  private conditionallyRemoveCouponField = <T extends PurchaseReceiptPriceParams>(params: T): T => {
    // If RSA CDI is present and offer is not claimed, remove the RSA related coupon from our Xgrit API request
    if (this.isDiscountConditional && this.rsaComponentLoaded && this.rsaClaimStatus === false) {
      // Remove the RSA related coupon code
      if (params.couponCodeList) {
        const couponCode = this.route.snapshot.queryParamMap.get('coupon'); // Coupon code associated with RSA
        const bundleCouponCode = this.route.snapshot.queryParamMap.get('bundleCoupon'); // Bundle coupon code associated with RSA. Used for IDS and DEC FL.DE bundle upsell

        const indexCoupon = params.couponCodeList.indexOf(couponCode);
        if (indexCoupon !== -1) {
          params.couponCodeList.splice(indexCoupon, 1);
        }

        const indexBundleCoupon = params.couponCodeList.indexOf(bundleCouponCode);
        if (indexBundleCoupon !== -1) {
          params.couponCodeList.splice(indexBundleCoupon, 1);
        }
      }

      // Remove the RSA coupon ID. Xgrit API purchase endpoint doesn't support couponCodeList, only couponIdList.
      if (params.couponIdList) {
        const index = params.couponIdList.indexOf(this._couponCodeId);
        if (index !== -1) {
          params.couponIdList.splice(index, 1);
        }
      }
    }

    return params;
  };

  // AARP API
  getAarpMembership = (params: AarpMembershipSubParams): Observable<XgritAarpResponse> => {
    return this.postXgritResource<XgritAarpResponse>(XccApiEndpoints.aarpMembership, params);
  };

  // Get product and total pricing for each products as it relates to the coupon
  getPurchaseReceiptPrice = (
    params: PurchaseReceiptPriceParams,
    bypass?: boolean,
  ): Observable<XgritPricingResponse> => {
    const endpoint = XccApiEndpoints.xgritPurchasePrice;
    if (this.isPrepaid || bypass) {
      return this.postXgritResource<XgritPricingResponse>(endpoint, params);
    } else {
      const paramsMutated = this.conditionallyRemoveCouponField<PurchaseReceiptPriceParams>(params);
      return this.postXgritResource<XgritPricingResponse>(endpoint, paramsMutated);
    }
  };

  /**
   * On client side, makes get request to the `/coupon` API endpoint and returns the coupon information if it is sucessful
   * @param {CouponParams} params values to request the coupon information
   * @returns {Observable<XgritCouponResponse>} Coupon response
   */
  getCoupon = (params: CouponParams): Observable<XgritCouponResponse> => {
    const endpoint = XccApiEndpoints.xgritCoupon;
    return this.postXgritResource<XgritCouponResponse>(endpoint, params);
  };

  // Makes an Xgrit API Stripe purchase and, if successful, the user is provisioned.
  makePurchase = (params: PurchaseAnonymousParams): Observable<PurchaseAnonymousResponse> => {
    const endpoint = XccApiEndpoints.xgritPurchase;
    if (this.isPrepaid) {
      return this.postXgritResource<PurchaseAnonymousResponse>(endpoint, params);
    } else {
      const paramsMutated = this.conditionallyRemoveCouponField(params);
      return this.postXgritResource<PurchaseAnonymousResponse>(endpoint, paramsMutated);
    }
  };

  // Makes an Xgrit API Stripe purchase of a video course
  makeVideoPurchase = (params: VideoPurchaseAnonymousParams): Observable<PurchaseAnonymousResponse> => {
    const paramsMutated = this.conditionallyRemoveCouponField(params);
    return this.postXgritResource<PurchaseAnonymousResponse>(XccApiEndpoints.xgritPurchaseVideo, paramsMutated);
  };

  // Reset the user's password
  passwordReset = (params: PasswordResetParams): Observable<PasswordChangeResponse> => {
    return this.postXgritResource<PasswordChangeResponse>(XccApiEndpoints.xgritPasswordReset, params);
  };

  // Enroll the user in RSA
  enrollRsa = (params: EnrollRsaParams): Observable<EnrollRsaResponse> => {
    return this.postXgritResource<EnrollRsaResponse>(XccApiEndpoints.xgritEnrollRsa, params);
  };

  /**
   * Function to get the purchase receipt status, this is used for Buy Now Pay Later payments
   * @param {String} params.brandId
   * @param {String} params.receiptId
   * @returns PurchaseReceiptStatusResponse
   */
  getPurchaseReceiptStatus = (params: PurchaseReceiptStatusParams): Observable<PurchaseReceiptStatusResponse> => {
    return this.postXgritResource<PurchaseReceiptStatusResponse>(XccApiEndpoints.xgritPurchaseReceiptStatus, params);
  };

  getMultiprice = (params: MultipriceParams): Observable<MultipriceResponse> => {
    return this.postXgritResource<MultipriceResponse>(XccApiEndpoints.xgritMultiprice, params);
  };

  /**
   * Retrieves the default products based on the provided parameters.
   * @param params - The parameters for retrieving the default products.
   * @returns An observable that emits an array of XgritProduct objects.
   */
  getDefaultProducts = (params: DefaultProductsParams): Observable<XgritProduct[]> => {
    return this.postXgritResource<XgritProduct[]>(XccApiEndpoints.xgritDefault, params);
  };

  verifyEmail = (params: VerifyEmailParams): Observable<SuccessResponse> => {
    const body = {
      ...params,
      brandId: this.brandId === Brand.AA ? Brand.ACE : this.brandId,
      idempotencyKey: this.idService.uniqueIdentifier,
    };

    const resourceUrl = this.xccEnv.xgritApiHostUrl + XccApiEndpoints.xgritVerifyEmail

    return this.http.post<SuccessResponse>(resourceUrl, body, { headers: { Authorization: this.xccEnv.xgritAuthorization } } ).pipe(
      catchError((error: HttpErrorResponse) => {
        return of({
          "success": true,
          "code": 200,
          "message": "Exceed Limit",
          "data": {
              "verifyEmail": false
          }
        });
      }));
  }

  errorHandling = (error: HttpErrorResponse): ErrorResponse => {
    let isCommonError = false;

    const CommonErrors = [
      {
        message: 'coupon',
        code: 40402
      }
    ];

    const errorList = error.error?.errorList || [];
    if(errorList.length > 0) {
      isCommonError = CommonErrors.some(commonError => 
        errorList[0].message.toUpperCase().includes(commonError.message.toUpperCase()) && commonError.code === errorList[0].code
      );
    }

    if(isCommonError) {
      console.log(error);
    }else{
      console.error(error);
    }

    return {
      status: error.status,
      statusText: error.statusText,
      errorList,
    } 
  }
}
